import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Input, TextField } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { createSession, getInsight, loadBotData } from "../../API/API";
import { makeNotif } from "../Toastify";
import { orderedKeys, user_sample } from "./sample";
import styles from "./whisper.module.css";

export default function Whisper() {
  const [appUsername, setAppUsername] = useState(Cookies.get("username"));
  const [appPassword, setAppPassword] = useState(Cookies.get("password"));
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [userData, setUserData] = useState(user_sample);
  const [conversation, setConversation] = useState("");
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1000,
    },
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await fetchData("form");
  };

  const fetchData = async (source) => {
    if ((appUsername && appPassword) || source === "form") {
      try {
        const data = await loadBotData(appUsername, appPassword);
        if (data.message !== "Incorrect credentials") {
          Cookies.set("username", appUsername);
          Cookies.set("password", appPassword);
          setInvalidCredentials(false);
          setModalIsOpen(false);
        } else {
          setInvalidCredentials(true);
          setModalIsOpen(true);
          Cookies.remove("username");
          Cookies.remove("password");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          setModalIsOpen(true);
        }
      }
    }
  };

  const handleDemographyChange = (field, value) => {
    setUserData((prevData) => ({
      ...prevData,
      message: {
        ...prevData.message,
        demography: { ...prevData.message.demography, [field]: value },
      },
    }));
  };

  const handleLastClinicalIntakeChange = (index, value) => {
    setUserData((prevData) => ({
      ...prevData,
      message: {
        ...prevData.message,
        last_clinical_intake: prevData.message.last_clinical_intake.map(
          (appointment, i) => {
            if (i === index) {
              return {
                ...appointment,
                Answer: value,
              };
            }
            return appointment;
          }
        ),
      },
    }));
  };

  const handleAppointmentChange = (index, field, value) => {
    setUserData((prevData) => ({
      ...prevData,
      message: {
        ...prevData.message,
        appointment_history: prevData.message.appointment_history.map(
          (appointment, i) => {
            if (i === index) {
              return {
                ...appointment,
                [field]: value,
              };
            }
            return appointment;
          }
        ),
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const createSessionResponse = await createSession(userData);
      const sessionId = createSessionResponse.data;
      const getInsightResponse = await getInsight(conversation, sessionId);
      setResult(getInsightResponse.data.result);
    } catch (error) {
      makeNotif("error", "An unexpected error accured");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.informationContainer}>
      <Modal isOpen={modalIsOpen} style={modalStyle}>
        <form className={styles.loginForm} onSubmit={handleLogin}>
          <Input
            type="text"
            name="username"
            placeholder="Username"
            value={appUsername}
            onChange={(e) => setAppUsername(e.target.value)}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            value={appPassword}
            onChange={(e) => setAppPassword(e.target.value)}
          />
          {invalidCredentials && (
            <p style={{ color: "red", margin: 0 }}>Invalid credentials</p>
          )}
          <button className={styles.button}>Login</button>
        </form>
      </Modal>
      {!modalIsOpen && (
        <>
          <div className={styles.container}>
            <Demography
              demography={userData.message.demography}
              handleInputChange={handleDemographyChange}
            />
            <LastClinicalIntake
              last_clinical_intake={userData.message.last_clinical_intake}
              handleInputChange={handleLastClinicalIntakeChange}
            />
            <AppointmentHistory
              appointments={userData.message.appointment_history}
              handleInputChange={handleAppointmentChange}
            />
            <MessageHistory messages={userData.message.message_history} />
          </div>
          <div className={styles.conversation}>
            <textarea
              placeholder="Conversation"
              value={conversation}
              onChange={(e) => setConversation(e.target.value)}
            />
          </div>
          {result && (
            <div className={styles.result}>
              Result: {JSON.stringify(result)}
            </div>
          )}
          <button
            className="button button-progress"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </>
      )}
    </div>
  );
}

const Demography = ({ demography, handleInputChange }) => (
  <div className={styles.demography}>
    <h2>Demography</h2>
    {demography &&
      orderedKeys.map((key, index) => (
        <div key={index}>
          <TextField
            label={key.replace(/_/g, " ")}
            value={demography[key] || ""}
            onChange={(e) => handleInputChange(key, e.target.value)}
            fullWidth
          />
        </div>
      ))}
  </div>
);

const LastClinicalIntake = ({ last_clinical_intake, handleInputChange }) => (
  <div className={styles.lastClinicalIntake}>
    <h2>Last Clinical Intake</h2>
    {last_clinical_intake?.map((item, index) => (
      <div key={index}>
        <TextField
          label={item.Question}
          value={item.Answer || ""}
          onChange={(e) => handleInputChange(index, e.target.value)}
          fullWidth
        />
      </div>
    ))}
  </div>
);

const AppointmentHistory = ({ appointments, handleInputChange }) => {
  return (
    <div className={styles.appointmentHistory}>
      <h2>Appointment History</h2>
      {appointments?.map((appointment, index) => (
        <div key={index} className={styles.appointmentContainer}>
          <TextField
            label="LOC"
            value={appointment.LOC || ""}
            onChange={(e) =>
              handleInputChange(index, "clinical_notes", e.target.value)
            }
            fullWidth
            margin="normal"
          />

          <TextField
            label="Reason for Call"
            value={appointment.reason_for_call || ""}
            onChange={(e) =>
              handleInputChange(index, "clinical_notes", e.target.value)
            }
            fullWidth
            margin="normal"
          />

          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: { items: ["undo", "redo", "|", "bold", "italic"] },
            }}
            data={appointment.clinical_notes || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleInputChange(index, "clinical_notes", data);
            }}
          />
          <hr />
        </div>
      ))}
    </div>
  );
};

const MessageHistory = ({ messages }) => (
  <div className={styles.messageHistory}>
    <h2>Message History</h2>
    {messages?.map((message, index) => (
      <div key={index} className={`${styles.message} ${styles[message.user]}`}>
        <p className={styles.messageDate}>{message.date}</p>
        <div className={styles.messageBubble}>{message.message}</div>
      </div>
    ))}
  </div>
);
