import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getContents, loadMessages } from "../../API/API";
import { makeNotif } from "../Toastify";
import "./contentIds.css";
export default function ContentIds() {
  const [contents, setContents] = useState([]);
  const [isSessionContent, setIsSessionContent] = useState(false);
  const [sessionContent, setSessionContent] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const contentIds = queryParameters.get("contentIds");
  const sessionIds = queryParameters.get("sessionIds");
  const username = queryParameters.get("username");
  const password = queryParameters.get("password");

  const formatString = (str) => {
    let result = str.replace(/_/g, " ");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  const fetchContent = async () => {
    setLoading(true);
    try {
      if (sessionIds === "true") {
        setIsSessionContent(true);
        try {
          const res = await loadMessages(username, password);
          setSessionContent(res.sessions_ids);
        } catch (error) {
          makeNotif("error", "An error accured");
        } finally {
          setLoading(false);
        }
        return;
      }
      const res = await getContents(contentIds);
      setContents(res);
    } catch (error) {
      console.log("error fetching content: ", error);
    } finally {
      setLoading(false);
    }
  };

  console.log(Object.entries(sessionContent));
  useEffect(() => {
    fetchContent();
  }, []);

  return (
    <div className="contents-container">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {isSessionContent ? (
            <>
              {Object.entries(sessionContent).map((content, idx) => (
                <div className="contents">
                  <h2>{formatString(content[0])}</h2>
                  <ol>
                    {content[1].map((contentItem) => (
                      <li className="content0itam">{contentItem}</li>
                    ))}
                  </ol>
                </div>
              ))}
            </>
          ) : (
            <div className="contents">
              <b>Contents</b>
              <ol>
                {contents?.map((content) => (
                  <li className="content-item">{content}</li>
                ))}
              </ol>
            </div>
          )}
        </>
      )}
    </div>
  );
}
