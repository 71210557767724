import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  Button,
  FormControl,
  Input,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import Cookies from "js-cookie";
import { SpeechPlayer } from "openai-speech-stream-player";
import React, { useEffect, useRef, useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Modal from "react-modal";
import Select from "@mui/material/Select";
import { FaVolumeMute } from "react-icons/fa";

import {
  deleteMessages,
  getTestUserInformation,
  getTestUsers,
  interactive,
  interactiveNew,
  loadBotData,
  loadMessages,
  loadMessagesNew,
  postUserMessage,
  postUserMessageNew,
} from "../../API/API";
import { makeNotif } from "../Toastify";
import "./Chatbox.css";
import "./VoiceRecorder.css";
function Chatbox({ setType, setVersion }) {
  const [response, setResponse] = useState();
  const [messages, setMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const [recording, setRecording] = useState(false);
  const audioRef = useRef(null);
  const playerRef = useRef(null);
  const [loginModalsOpen, setLoginModalIsOpen] = useState(true);
  const [newUserModalIsOpen, setNewUserModalIsOpen] = useState(false);
  const [username, setUsername] = useState(Cookies.get("username"));
  const [password, setPassword] = useState(Cookies.get("password"));
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [memberUsername, setMemberUsername] = useState("");
  const [memberPassword, setMemberPassword] = useState("");
  const [selectedVoice, setSelectedVoice] = useState("");
  const [comment, setComment] = useState("");
  const [information, setInformation] = useState({});
  const [memory, setMemory] = useState([]);
  const [state, setState] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [message, setMessage] = useState("");
  const [freeToSpam, setFreeToSpam] = useState(false);
  const [contentIds, setContentIds] = useState();
  const [sessionIds, setSessionIds] = useState();
  const timerRef = useRef(null);
  const [testUsers, setTestUsers] = useState([]);
  const [selectedTestUser, setSelectedTestUser] = useState("");
  const [selectedUserInformation, setSelectedUserInformation] = useState();
  const [loadingUser, setLoadingUser] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const femaleVoices = ["alloy", "nova", "shimmer"];
  const [useBotVoice, setUseBotVoice] = useState(true);
  const maleVoices = ["onyx", "echo"];

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1000,
    },
  };

  const handleMicrophoneAccess = (e) => {
    makeNotif("info", "Give microphone access to the browser first");
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .catch((e) => console.error(e));
    } else {
      makeNotif("info", "Audio recording not supported in this browser");
    }
  };

  const getCurrentTime = () => {
    const currentDate = new Date();
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const transcriptVoice = async (audioFile) => {
    const formData = new FormData();
    formData.append("file", audioFile);
    formData.append("model", "whisper-1");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Cache-Control", "no-store");
      myHeaders.append(
        "Authorization",
        `Bearer ${process.env.REACT_APP_API_KEY}`
      );

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };
      const response = await fetch(
        "https://api.openai.com/v1/audio/transcriptions",
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const transcript = await response.json();
      return transcript;
    } catch (error) {
      console.error(error);
    }
  };

  const recordVoice = async (blob) => {
    if (memberUsername === "") {
      makeNotif("error", "Please enter a username first");
    } else {
      const startTime = new Date();
      const modifiedBlob = new Blob([blob], { type: "audio/ogg" });
      const url = URL.createObjectURL(modifiedBlob);
      const audioFile = new File([blob], "audiofile.ogg", {
        type: "audio/ogg",
      });
      let newUserMessage;
      let transcript;
      try {
        transcript = await transcriptVoice(audioFile);
        if (transcript?.text) {
          newUserMessage = {
            idx: messages?.length + 1,
            message: transcript.text,
            html: (
              <>
                <p style={{ margin: "0" }}>{transcript.text}</p>
                {/* <AudioPlayer src={url} style={{ minWidth: "200px" }} controls /> */}
              </>
            ),
            sender: "user",
            direction: "outgoing",
            sentTime: getCurrentTime(),
            type: "custom",
          };
          messages?.length > 0
            ? setMessages((prevMessages) => [...prevMessages, newUserMessage])
            : setMessages([newUserMessage]);
          try {
            if (window.location.href.includes("chat/new")) {
              const res = await postUserMessageNew(transcript.text, sessionId);
              setMemory(res.Memory);

              const messagesLength = messages?.length + 1;
              const newBotMessage = {
                idx: messages?.length + 1,
                message: res.Message,
                html: (
                  <div>
                    <p style={{ marginTop: "0" }}>{res.Message}</p>
                  </div>
                ),
                sender: "coach",
                sentTime: getCurrentTime(),
                type: "custom",
              };
              setMessages((prevMessages) => [...prevMessages, newBotMessage]);
              if (res.Interactive?.length > 0) {
                const newBotMessage1 = {
                  interactive: true,
                  idx: messages?.length + 1,
                  message: res.output,
                  state: res.message_state,
                  showButton: true,
                  answer: "",
                  interactiveData: res.Interactive[0],
                  sender: "coach",
                  sentTime: getCurrentTime(),
                  type: "custom",
                  direction: "incoming",
                };
                setMessages((prevMessages) => [
                  ...prevMessages,
                  newBotMessage1,
                ]);
              }
              useBotVoice &&
                streamTTS(res.Message, messagesLength + 1, "alloy");
              return;
            }
            let messagesToSend = [...messages];
            messagesToSend.push(newUserMessage);
            messagesToSend = messagesToSend.map((obj) => {
              const { direction, html, type, idx, ...rest } = obj;
              return rest;
            });
            messagesToSend = messagesToSend.filter((obj) => {
              return obj.newSession !== true && obj.interactive !== true;
            });
            const data = await postUserMessage(
              messagesToSend,
              memberUsername,
              selectedTestUser.userid,
              false
            );
            if (data) {
              const messagesLength = messages?.length + 1;
              setTyping(true);
              setContentIds(data.contentIds);
              setSessionIds(data.sessions_ids);
              const newBotMessage = {
                idx: messagesLength + 1,
                state: data.message_state,
                message: data.output,
                html: (
                  <div>
                    <p style={{ marginTop: "0" }}>{data.output}</p>
                  </div>
                ),
                sender: "coach",
                sentTime: getCurrentTime(),
                type: "custom",
              };
              setMessages((prevMessages) => [...prevMessages, newBotMessage]);
              if (data.Interactive?.length > 0) {
                const newBotMessage1 = {
                  interactive: true,
                  idx: messages?.length + 1,
                  message: data.output,
                  state: data.message_state,
                  showButton: true,
                  answer: "",
                  interactiveData: data.Interactive[0],
                  sender: "coach",
                  sentTime: getCurrentTime(),
                  type: "custom",
                  direction: "incoming",
                };
                setMessages((prevMessages) => [
                  ...prevMessages,
                  newBotMessage1,
                ]);
              }
              useBotVoice &&
                streamTTS(data.output, messagesLength + 1, "alloy");
              setInformation(data.information);
              setState(data.state);
              if (data.new_session_flag) {
                setMessages((prev) => [...prev, { newSession: true }]);
              }
            }
          } catch (error) {
            setTyping(false);
            makeNotif("error", error);
          }
        } else {
          makeNotif("error", "OpenAI STT API is not working correctly");
        }
      } catch (error) {
        makeNotif("error", "Error occurred while processing the voice file.");
      }
    }
  };

  const handleSubmitUsername = async () => {
    if (memberUsername === "") {
      makeNotif("error", "Please enter User Identification");
    } else if (!selectedTestUser) {
      makeNotif("error", "Please select a user ");
    } else {
      pauseStream();
      try {
        setMessages([]);
        if (window.location.href.includes("chat/new")) {
          setMemory([]);
          setLoadingUser(true);
          const res = await getTestUserInformation(selectedTestUser.userid);
          setLoadingUser(false);
          setSelectedUserInformation(res.data);
          const id = await loadMessagesNew(res.data.message, memberUsername);
          setSessionId(id);
          makeNotif("success", "Conversation created successfully!");
          const firstMessage = await postUserMessageNew("/?start", id);
          setMemory(firstMessage.Memory);
          const messagesLength = messages?.length + 1;
          const newBotMessage = {
            idx: messages?.length + 1,
            message: firstMessage.Message,
            html: (
              <div>
                <p style={{ marginTop: "0" }}>{firstMessage.Message}</p>
              </div>
            ),
            sender: "coach",
            sentTime: getCurrentTime(),
            type: "custom",
          };
          setMessages((prevMessages) => [...prevMessages, newBotMessage]);
          useBotVoice &&
            streamTTS(firstMessage.Message, messagesLength, "alloy");
          return;
        }
        const res = await loadMessages(
          memberUsername,
          selectedTestUser.userid.toString()
        );
        if (res.is_new_account) {
          setNewUserModalIsOpen(true);
          return;
        }
        setInformation(res.information);
        setSelectedVoice(res.coach_name);
        setContentIds(res.content_ids);
        setSessionIds(res.sessions_ids);
        // if (res.coach_gender === "m") {
        //   const voice = getRandomValueFromArray(maleVoices);
        //   setSelectedVoice(voice);
        // } else if (res.coach_gender === "f") {
        //   const voice = getRandomValueFromArray(femaleVoices);
        //   setSelectedVoice(voice);
        // }
        setState(res.state);
        res?.conversation?.map((message) =>
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              message: message.message,
              sender: message.sender,
              sentTime: message.sentTime,
              state: message.state,
              direction: `${(message.sender === "user" || message.sender === "comment") &&
                "outgoing"
                }`,
              html: (
                <div>
                  <p>{message.message}</p>
                </div>
              ),
              type: "text",
            },
          ])
        );
        makeNotif("success", "Username selected successfully");
      } catch (error) {
        makeNotif("error", "An error accured");
        console.error(error);
      }
    }
  };

  const handleDelete = async () => {
    pauseStream();
    try {
      await deleteMessages(memberUsername, selectedTestUser.userid.toString());
      setMemberUsername("");
      setMemberPassword("");
      setInformation({});
      setMemory([]);
      setMessages([]);
      setState("");
      setContentIds([]);
      setSessionIds();
      makeNotif("success", "Deleted successfully");
    } catch (err) {
      makeNotif("error", "An error accured");
    }
  };

  const pauseStream = () => {
    const audio = document.getElementById("stream");
    audio?.pause();
  };

  const streamTTS = async (text, messageIdx, coachName) => {
    var myHeaders = new Headers();
    myHeaders.append("Cache-Control", "no-store");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${process.env.REACT_APP_API_KEY}`
    );
    const raw = JSON.stringify({
      model: "tts-1",
      input: text,
      voice: "nova",
      stream: true,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const mimeType = "audio/mpeg";

      if (!MediaSource.isTypeSupported(mimeType)) {
        console.warn(
          `MIME type ${mimeType} is not supported by MediaSource. Using fallback.`
        );

        const response = await fetch(
          "https://api.openai.com/v1/audio/speech",
          requestOptions
        );
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          const messageToUpdate = newMessages[messageIdx - 1];
          const updatedMessage = {
            ...messageToUpdate,
            html: (
              <div>
                <p style={{ marginTop: "0" }}>{messageToUpdate.message}</p>
                <FaVolumeMute
                  onClick={pauseStream}
                  size={20}
                  className="mute-icon"
                />
                {url && (
                  <AudioPlayer
                    src={url}
                    style={{ minWidth: "200px" }}
                    controls
                  />
                )}
              </div>
            ),
          };
          newMessages[messageIdx - 1] = updatedMessage;
          return newMessages;
        });
        setRecording(false);
        setTyping(false);
        return;
      }

      const player = new SpeechPlayer({
        audio: audioRef.current,
        onPlaying: () => { },
        onPause: () => { },
        onChunkEnd: () => { },
      });

      await player.init();
      playerRef.current = player;

      if (player.mediaSource.readyState !== "open") {
        throw new Error("MediaSource not opened");
      }

      const stream = await fetch(
        "https://api.openai.com/v1/audio/speech",
        requestOptions
      );

      for await (const chunk of streamAsyncIterable(
        stream.clone().body,
        stream.clone(),
        text,
        messageIdx
      )) {
        if (player.mediaSource.readyState === "open") {
          player.feed(chunk);
        } else {
          console.warn("MediaSource is not open. Skipping chunk.");
        }
      }
    } catch (error) {
      console.error("Error streaming audio:", error);
    }
  };

  async function* streamAsyncIterable(stream, res, text, idx) {
    const reader = stream.getReader();

    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          const blob = await res.blob();
          const url = URL.createObjectURL(blob);
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            const messageToUpdate = newMessages[idx - 1];
            const updatedMessage = {
              ...messageToUpdate,
              html: (
                <div>
                  <p style={{ marginTop: "0" }}>{messageToUpdate.message}</p>
                  <FaVolumeMute
                    onClick={pauseStream}
                    size={20}
                    className="mute-icon"
                  />
                  {url && (
                    <div>
                      <AudioPlayer
                        src={url}
                        style={{ minWidth: "200px" }}
                        controls
                      />
                    </div>
                  )}
                </div>
              ),
            };
            newMessages[idx - 1] = updatedMessage;
            return newMessages;
          });
          setRecording(false);
          setTyping(false);
          return;
        }
        yield value;
      }
    } finally {
      reader.releaseLock();
    }
  }

  let timer = null;

  useEffect(() => {
    if (freeToSpam) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        handleSend(message);
        setMessage("");
        setFreeToSpam(false);
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [userMessage, message]);

  const checkSpam = (userMessage) => {
    if (memberUsername === "") {
      makeNotif("error", "Please enter a username first");
      return;
    }

    setUserMessage("");
    setFreeToSpam(true);
    if (timer) {
      clearTimeout(timer);
    }

    if (message === "" || userMessage.interactive) {
      setMessage(userMessage);
      let newUserMessage;
      if (userMessage.interactive) {
        setMessages((prevArray) => prevArray.slice(0, -1));
        newUserMessage = {
          idx: messages?.length + 1,
          message: "",
          html: <p>{userMessage.message}</p>,
          Interactive: [
            { BtnId: userMessage.btnId, Clicked: userMessage.message },
          ],
          sender: "user",
          type: "custom",
          direction: "outgoing",
          sentTime: getCurrentTime(),
        };
      } else {
        newUserMessage = {
          idx: messages?.length + 1,
          message: userMessage.interactive ? userMessage.message : userMessage,
          html: (
            <p>{userMessage.interactive ? userMessage.message : userMessage}</p>
          ),
          sender: "user",
          direction: "outgoing",
          sentTime: getCurrentTime(),
        };
      }
      if (messages?.length > 0) {
        setMessages((prevMessages) => [...prevMessages, newUserMessage]);
      } else {
        setMessages([newUserMessage]);
      }
    } else {
      setMessage((prev) => prev + "\n" + userMessage);
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        const updatedMessage = {
          ...lastMessage,
          message: lastMessage.message + "\n" + userMessage,
          html: <p>{lastMessage.message + "\n" + userMessage}</p>,
        };
        return [
          ...prevMessages.slice(0, prevMessages.length - 1),
          updatedMessage,
        ];
      });
    }
  };

  const handleSend = async (message) => {
    if (memberUsername === "") {
      makeNotif("error", "Please enter a username first");
    } else {
      pauseStream();
      setTyping(true);
      let messagesToSend = [...messages];
      messagesToSend = messagesToSend?.map((obj) => {
        const { direction, html, type, idx, ...rest } = obj;
        return rest;
      });

      messagesToSend = messagesToSend.filter((obj) => {
        return obj.newSession !== true && obj.interactive !== true;
      });

      try {
        if (window.location.href.includes("chat/new")) {
          const res = await postUserMessageNew(message, sessionId);
          setMemory(res.Memory);
          const messagesLength = messages?.length + 1;
          const newBotMessage = {
            idx: messages?.length + 1,
            message: res.Message,
            html: (
              <div>
                <p style={{ marginTop: "0" }}>{res.Message}</p>
              </div>
            ),
            sender: "coach",
            sentTime: getCurrentTime(),
            type: "custom",
          };
          setMessages((prevMessages) => [...prevMessages, newBotMessage]);
          if (res.Interactive?.length > 0) {
            const newBotMessage1 = {
              interactive: true,
              idx: messages?.length + 1,
              message: res.output,
              state: res.message_state,
              showButton: true,
              answer: "",
              interactiveData: res.Interactive[0],
              sender: "coach",
              sentTime: getCurrentTime(),
              type: "custom",
              direction: "incoming",
            };
            setMessages((prevMessages) => [...prevMessages, newBotMessage1]);
          }
          useBotVoice && streamTTS(res.Message, messagesLength, "alloy");
          return;
        }
        const data = await postUserMessage(
          messagesToSend,
          memberUsername,
          selectedTestUser.userid,
          false
        );
        setResponse(data.output);
        setInformation(data.information);
        setContentIds(data.content_ids);
        setSessionIds(data.sessions_ids);
        setState(data.state);
        const newSession = data.new_session_flag;
        if (newSession) {
          setMessages((prev) => [...prev, { newSession: true }]);
        }
        const messagesLength = messages?.length + 1;
        const newBotMessage = {
          idx: messages?.length + 1,
          message: data.output,
          state: data.message_state,
          html: (
            <div>
              <p style={{ marginTop: "0" }}>{data.output}</p>
            </div>
          ),
          sender: "coach",
          sentTime: getCurrentTime(),
          type: "custom",
        };
        setMessages((prevMessages) => [...prevMessages, newBotMessage]);
        if (data.Interactive?.length > 0) {
          const newBotMessage1 = {
            interactive: true,
            idx: messages?.length + 1,
            message: data.output,
            state: data.message_state,
            showButton: true,
            answer: "",
            interactiveData: data.Interactive[0],
            sender: "coach",
            sentTime: getCurrentTime(),
            type: "custom",
            direction: "incoming",
          };
          setMessages((prevMessages) => [...prevMessages, newBotMessage1]);
        }
        useBotVoice && streamTTS(data.output, messagesLength, "alloy");
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        setTyping(false);
      }
    }
  };

  const handleSendInteractive = async (button, data, i) => {
    try {
      const res = window.location.href.includes("chat/new")
        ? await interactiveNew(
          [
            {
              Clicked: button,
              BtnId: data.BtnId,
            },
          ],
          sessionId
        )
        : await interactive(memberUsername, selectedTestUser.userid, [
          {
            Clicked: button,
            BtnId: data.BtnId,
          },
        ]);
      const newMessages = [...messages];
      newMessages[i].showButton = false;
      newMessages[i].answer = button;
      setMessages(newMessages);
      console.log(res);
      if (res.data.status === 200 && res.data.message !== "") {
        const messagesLength = messages?.length + 1;
        const newBotMessage = {
          idx: messages?.length + 1,
          message: res.data.message,
          html: (
            <div>
              <p style={{ marginTop: "0" }}>{res.data.message}</p>
            </div>
          ),
          sender: "coach",
          sentTime: getCurrentTime(),
          type: "custom",
        };
        setMessages((prevMessages) => [...prevMessages, newBotMessage]);
        useBotVoice && streamTTS(res.data.message, messagesLength, "alloy");
      }
    } catch (error) {
      makeNotif("error", "An error accured!");
      console.error(error);
    }
  };

  const handleSendComment = async () => {
    if (comment === "") {
      makeNotif("error", "Please enter a comment first");
    } else {
      const newUserMessage = {
        idx: messages?.length + 1,
        message: comment,
        html: (
          <>
            <p style={{ margin: "0" }}>{comment}</p>
            {/* <AudioPlayer src={url} style={{ minWidth: "200px" }} controls /> */}
          </>
        ),
        sender: "comment",
        direction: "outgoing",
        sentTime: getCurrentTime(),
        type: "custom",
      };
      let messagesToSend = [...messages, newUserMessage];
      messagesToSend = messagesToSend?.map((obj) => {
        const { direction, html, type, idx, ...rest } = obj;
        return rest;
      });

      messagesToSend = messagesToSend.filter((obj) => {
        return obj.newSession !== true && obj.interactive !== true;
      });
      setMessages((prevMessages) => [...prevMessages, newUserMessage]);
      setComment("");

      try {
        const res = await postUserMessage(
          messagesToSend,
          memberUsername,
          selectedTestUser.userid,
          true
        );
        setContentIds(res.content_ids);
        setSessionIds(res.sessions_ids);
      } catch (error) {
        console.error("error sending comment: ", error);
      }
    }
  };

  function getRandomValueFromArray(array) {
    const randomIndex = Math.floor(Math.random() * array?.length);
    const randomValue = array[randomIndex];
    return randomValue;
  }

  const recordComment = async (blob) => {
    const modifiedBlob = new Blob([blob], { type: "audio/ogg" });
    const url = URL.createObjectURL(modifiedBlob);
    const audioFile = new File([blob], "audiofile.ogg", {
      type: "audio/ogg",
    });
    const transcript = await transcriptVoice(audioFile);
    if (transcript.text) {
      setComment(transcript.text);
    } else {
      makeNotif("error", "OpenAI STT is not working correctly");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await fetchData("form");
  };

  const handleCreateNewUser = async () => {
    try {
      const res = await loadMessages(
        memberUsername,
        selectedTestUser.userid,
        true
      );
      setInformation(res.information);
      setSelectedVoice(res.coach_name);
      setState(res.state);
      setSessionIds(res?.sessions_ids)
      res?.conversation?.map((message) =>
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            message: message.message,
            sender: message.sender,
            sentTime: message.sentTime,
            state: message.state,
            direction: `${(message.sender === "user" || message.sender === "comment") &&
              "outgoing"
              }`,
            html: (
              <div>
                <p>{message.message}</p>
              </div>
            ),
            type: "custom",
          },
        ])
      );
      useBotVoice && streamTTS(res.conversation[0].message, 1, res.coach_name);
      makeNotif("success", "Username selected successfully");
      setNewUserModalIsOpen(false);
    } catch (error) {
      makeNotif("error", "An error occurred");
    }
  };

  const fetchData = async (source) => {
    if ((username && password) || source === "form") {
      try {
        const data = await loadBotData(username, password);
        if (data.message !== "Incorrect credentials") {
          Cookies.set("username", username);
          Cookies.set("password", password);
          const fetchTestUsers = async () => {
            const res = await getTestUsers();
            return res;
          };
          fetchTestUsers().then((res) => {
            setTestUsers(res.data.message);
          });
          setInvalidCredentials(false);
          setResponse(data);
          setType(data.type);
          setVersion(data.version);
          setLoginModalIsOpen(false);
        } else {
          setInvalidCredentials(true);
          setLoginModalIsOpen(true);
          Cookies.remove("username");
          Cookies.remove("password");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          setLoginModalIsOpen(true);
        }
      }
    }
  };

  useEffect(() => {
    setSelectedVoice(getRandomValueFromArray(femaleVoices));
    fetchData();
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .catch(() =>
          makeNotif(
            "error",
            "You need to give microphone access to be able to record voice!"
          )
        );
    } else {
      makeNotif("info", "Audio recording not supported in this browser");
    }
  }, []);

  useEffect(() => {
    if (username === undefined || password === undefined) {
      setLoginModalIsOpen(true);
    } else {
      const fetchTestUsers = async () => {
        const res = await getTestUsers();
        return res;
      };
      fetchTestUsers().then((res) => {
        setTestUsers(res.data.message);
      });
    }
  }, []);

  return (
    <div className="container">
      <Modal isOpen={loginModalsOpen} style={modalStyle}>
        <form className="login-form" onSubmit={handleLogin}>
          <Input
            type="text"
            name="username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {invalidCredentials && (
            <p style={{ color: "red", margin: 0 }}>Invalid credentials</p>
          )}
          <button className="button">Login</button>
        </form>
      </Modal>
      <Modal isOpen={newUserModalIsOpen} style={modalStyle}>
        <p>This is a new account. Do you want to create a new account? </p>
        <div className="new-user-modal">
          <Button variant="contained" onClick={handleCreateNewUser}>
            Yes
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setNewUserModalIsOpen(false);
              setMemberUsername("");
              setMemberPassword("");
            }}
          >
            No
          </Button>
        </div>
      </Modal>
      <audio
        controls
        id="stream"
        ref={audioRef}
        style={{ display: "none" }}
        type="audio/mpeg"
      />
      <div className="left">
        <div className="username-input">
          <Input
            placeholder="User Identification"
            value={memberUsername}
            onChange={(e) => setMemberUsername(e.target.value)}
          />
          <div className="user-select">
            <FormControl fullWidth>
              <label>User</label>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectedTestUser}
                className="select"
                placeholder="Select a user"
                onChange={(e) => setSelectedTestUser(e.target.value)}
              >
                {testUsers.map((user, idx) => (
                  <MenuItem key={idx} value={user}>
                    <div className="menu-item">
                      <p style={{ fontWeight: "bold" }}>{user.firstname}</p>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <button
              className="button"
              style={{ width: "100%" }}
              onClick={() => {
                const url = selectedTestUser
                  ? `/information?userid=${selectedTestUser.userid}`
                  : "/information";
                window.open(url, "_blank");
              }}
            >
              Users Information
            </button>
          </div>
          <div className="buttons">
            <Button
              variant="contained"
              onClick={handleSubmitUsername}
              disabled={loadingUser}
            >
              {loadingUser ? "Loading..." : "Submit"}
            </Button>
            <Button variant="contained" onClick={handleDelete} color="error">
              Delete
            </Button>
          </div>
        </div>
        <div className="state">
          <p>
            <b>State: </b>
            {state}
          </p>
        </div>
        <div className="information">
          <ul>
            {information["Main Goal"] && (
              <li key="Main Goal">
                <strong>Main Goal:</strong> {information["Main Goal"]}
              </li>
            )}
            {memory.length > 0
              ? memory.map((field) => (
                <li key={field.Type}>
                  <strong>{field.Type}:</strong> {field.Identified}
                </li>
              ))
              : Object.entries(information).map(([key, value]) => {
                if (key === "Main Goal") return null;
                return (
                  <li key={key}>
                    <strong>{key}:</strong> {value}
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <div className="chat-box item">
        <MainContainer>
          <ChatContainer className="chat-container">
            <MessageList
              typingIndicator={
                typing && (
                  <TypingIndicator
                    style={{ backgroundColor: "#fff0" }}
                    content="Copilot is typing"
                  />
                )
              }
            >
              {messages?.map((message, i) => (
                <React.Fragment key={i}>
                  {messages[i + 1]?.newSession && (
                    <div className="new-session">
                      <div className="green-line"></div>
                      <p className="">New session</p>
                      <div className="green-line"></div>
                    </div>
                  )}
                  {message?.newSession !== true && !message.interactive && (
                    <>
                      <Message model={message} className={message.sender}>
                        {message.type === "custom" && (
                          <Message.CustomContent>
                            {message.html}
                          </Message.CustomContent>
                        )}
                      </Message>
                      <p className={`time${message.sender}`}>
                        {message.sentTime}
                      </p>
                    </>
                  )}
                  {message.interactive && (
                    <>
                      <Message model={message} className={message.sender}>
                        <Message.CustomContent>
                          <p>{message.interactiveData.Message}</p>
                          {message.showButton ? (
                            <div className="interactive-buttons">
                              {message.interactiveData.Choices.map((button) => (
                                <>
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      handleSendInteractive(
                                        button,
                                        message.interactiveData,
                                        i
                                      )
                                    }
                                  >
                                    {button}
                                  </Button>
                                </>
                              ))}
                            </div>
                          ) : (
                            message.answer
                          )}
                        </Message.CustomContent>
                      </Message>
                      <p className={`time${message.sender}`}>
                        {message.sentTime}
                      </p>
                    </>
                  )}
                </React.Fragment>
              ))}
            </MessageList>
            <div
              as="MessageInput"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <MessageInput
                placeholder="Type message here"
                onSend={checkSpam}
                className="message-input"
                id="message-input"
                value={userMessage}
                onChange={(e) => setUserMessage(e)}
                style={{
                  flexGrow: 1,
                  borderTop: 0,
                  flexShrink: "initial",
                  paddingBottom: "24px",
                }}
              />
              <div className="voice-recorder" onClick={pauseStream}>
                <AudioRecorder
                  onRecordingComplete={recordVoice}
                  onNotAllowedOrFound={handleMicrophoneAccess}
                />
              </div>
            </div>
          </ChatContainer>
        </MainContainer>
      </div>
      <div className="button-container item">
        <button
          className="button"
          onClick={() => window.open("/users", "_blank")}
        >
          Users
        </button>
        <button
          className="button"
          disabled={contentIds?.length === 0 || !contentIds}
          onClick={() =>
            window.open(`/content?contentIds=${contentIds}`, "_blank")
          }
        >
          CMS Contents
        </button>
        <button
          className="button"
          disabled={!sessionIds}
          onClick={() =>
            window.open(
              `/content?sessionIds=${true}&username=${memberUsername}&password=${selectedTestUser.userid.toString()}`,
              "_blank"
            )
          }
        >
          Session Content
        </button>
        <div className="record-comment" onClick={pauseStream}>
          <div className="use-bot-voice-container">
            <label>Voice Streaming</label>
            <div className="use-bot-voice">
              <p>Off</p>
              <Switch
                checked={useBotVoice}
                onChange={() => setUseBotVoice((prev) => !prev)}
              />
              <p>On</p>
            </div>
          </div>
          <b style={{ margin: 0 }}>Record Comment</b>
          <AudioRecorder
            onRecordingComplete={recordComment}
            onNotAllowedOrFound={handleMicrophoneAccess}
          />
        </div>
        <div className="edit-comment">
          <TextField
            multiline
            type="text-box"
            placeholder="Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <Button variant="contained" onClick={handleSendComment}>
            Send Comment
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Chatbox;
