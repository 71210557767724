// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.voice-recorder {
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    justify-content: center;
}

.rhap_container{
    padding: 0px 0px;
    background-color: #c6e3fa00;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);

}
.audio-recorder-timer, .audio-recorder-status{
color : #a7a5a5;
}
#dark .rhap_time {
color: whitesmoke;
}
.rhap_progress-indicator {
background-color: #7a8194;

}
.rhap_volume-controls{
display: none;

}
#dark .voice-recorder {
background-color: #1b202d00;
}

#light .voice-recorder {
background-color: #bbbbbb;
}

.audio-recorder {
margin-top: 5px;
box-shadow: 0 0px 0px #bebebe;
background-color: #dad8d8;
margin-bottom: 5px;
}
.recording{
width: 210px;

}

#dark .audio-recorder img {
background-color: #dad8d800;
color: white;
}

#light .audio-recorder img {
background-color: #dad8d8;
color: white;
}

.rhap_additional-controls {
display: none;
}

.rhap_button-clear .rhap_main-controls-button {
display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Chatbox/VoiceRecorder.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,2BAA2B;IAC3B,sCAAsC;;AAE1C;AACA;AACA,eAAe;AACf;AACA;AACA,iBAAiB;AACjB;AACA;AACA,yBAAyB;;AAEzB;AACA;AACA,aAAa;;AAEb;AACA;AACA,2BAA2B;AAC3B;;AAEA;AACA,yBAAyB;AACzB;;AAEA;AACA,eAAe;AACf,6BAA6B;AAC7B,yBAAyB;AACzB,kBAAkB;AAClB;AACA;AACA,YAAY;;AAEZ;;AAEA;AACA,2BAA2B;AAC3B,YAAY;AACZ;;AAEA;AACA,yBAAyB;AACzB,YAAY;AACZ;;AAEA;AACA,aAAa;AACb;;AAEA;AACA,aAAa;AACb","sourcesContent":[".voice-recorder {\n    display: flex;\n    padding-left: 5px;\n    padding-right: 5px;\n    align-items: center;\n    justify-content: center;\n}\n\n.rhap_container{\n    padding: 0px 0px;\n    background-color: #c6e3fa00;\n    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);\n\n}\n.audio-recorder-timer, .audio-recorder-status{\ncolor : #a7a5a5;\n}\n#dark .rhap_time {\ncolor: whitesmoke;\n}\n.rhap_progress-indicator {\nbackground-color: #7a8194;\n\n}\n.rhap_volume-controls{\ndisplay: none;\n\n}\n#dark .voice-recorder {\nbackground-color: #1b202d00;\n}\n\n#light .voice-recorder {\nbackground-color: #bbbbbb;\n}\n\n.audio-recorder {\nmargin-top: 5px;\nbox-shadow: 0 0px 0px #bebebe;\nbackground-color: #dad8d8;\nmargin-bottom: 5px;\n}\n.recording{\nwidth: 210px;\n\n}\n\n#dark .audio-recorder img {\nbackground-color: #dad8d800;\ncolor: white;\n}\n\n#light .audio-recorder img {\nbackground-color: #dad8d8;\ncolor: white;\n}\n\n.rhap_additional-controls {\ndisplay: none;\n}\n\n.rhap_button-clear .rhap_main-controls-button {\ndisplay: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
