import {
  CircularProgress,
  FormControl,
  Input,
  MenuItem,
  Select,
} from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  getTestUserInformation,
  getTestUsers,
  loadBotData,
} from "../../API/API";
import "../Chatbox/Chatbox.css";
import styles from "./information.module.css";
import { useSearchParams } from "react-router-dom";

const Demography = ({ demography, isLoading }) => (
  <div className={styles.demography}>
    <h2>Demography</h2>
    {isLoading ? (
      <CircularProgress />
    ) : (
      <>
        {demography &&
          Object.entries(demography).map(([key, value], index) => (
            <p key={index}>
              <strong>{key.replace(/_/g, " ")}:</strong> {value || "N/A"}
            </p>
          ))}
      </>
    )}
  </div>
);

const LastClinicalIntake = ({ last_clinical_intake, isLoading }) => (
  <div className={styles.lastClinicalIntake}>
    <h2>Last Clinical Intake</h2>
    {isLoading ? (
      <CircularProgress className={styles.spinner} />
    ) : (
      <>
        {last_clinical_intake?.map((item, index) => (
          <div key={index}>
            <p>
              <strong>{item.Question}:</strong> {item.Answer}
            </p>
            <hr />
          </div>
        ))}
      </>
    )}
  </div>
);

const AppointmentHistory = ({ appointments, isLoading }) => (
  <div className={styles.appointmentHistory}>
    <h2>Appointment History</h2>
    {isLoading ? (
      <CircularProgress className={styles.spinner} />
    ) : (
      <>
        {appointments?.map((appointment, index) => (
          <div key={index}>
            <p>
              <strong>LOC:</strong> {appointment.LOC}
            </p>
            <p>
              <strong>Reason for Call:</strong> {appointment.reason_for_call}
            </p>
            <div
              className={styles.clinicalNotes}
              dangerouslySetInnerHTML={{ __html: appointment.clinical_notes }}
            />
            <hr />
          </div>
        ))}
      </>
    )}
  </div>
);

const MessageHistory = ({ messages, isLoading }) => (
  <div className={styles.messageHistory}>
    <h2>Message History</h2>
    {isLoading ? (
      <CircularProgress className={styles.spinner} />
    ) : (
      <>
        {messages?.map((message, index) => (
          <div
            key={index}
            className={`${styles.message} ${styles[message.user]}`}
          >
            <p className={styles.messageDate}>{message.date}</p>
            <div className={styles.messageBubble}>{message.message}</div>
          </div>
        ))}
      </>
    )}
  </div>
);

export default function Information() {
  const [appUsername, setAppUsername] = useState(Cookies.get("username"));
  const [appPassword, setAppPassword] = useState(Cookies.get("password"));
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [testUsers, setTestUsers] = useState([]);
  const [selectedTestUser, setSelectedTestUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTestUserInformation, setSelectedTestUserInformation] =
    useState();

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1000,
    },
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await fetchData("form");
  };

  const fetchTestUsers = async () => {
    const res = await getTestUsers();
    return res;
  };

  const fetchData = async (source) => {
    if ((appUsername && appPassword) || source === "form") {
      try {
        const data = await loadBotData(appUsername, appPassword);
        if (data.message !== "Incorrect credentials") {
          Cookies.set("username", appUsername);
          Cookies.set("password", appPassword);
          setInvalidCredentials(false);
          setModalIsOpen(false);
          const usersResponse = await fetchTestUsers();
          const users = usersResponse.data.message;
          setTestUsers(users);
          const userid = searchParams.get("userid");
          if (userid) {
            const res = await getTestUserInformation(userid);
            setSelectedTestUserInformation(res.data.message);
            const user = users.find(
              (user) => user.userid.toString() === userid
            );
            setSelectedTestUser(user);
          }
        } else {
          setInvalidCredentials(true);
          setModalIsOpen(true);
          Cookies.remove("username");
          Cookies.remove("password");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          setModalIsOpen(true);
        }
      }
    }
  };

  const handleDropdownChange = async (e) => {
    try {
      setIsLoading(true);
      const res = await getTestUserInformation(e.target.value.userid);
      setSelectedTestUserInformation(res.data.message);
      setSelectedTestUser(e.target.value);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Modal isOpen={modalIsOpen} style={modalStyle}>
        <form className={styles.loginForm} onSubmit={handleLogin}>
          <Input
            type="text"
            name="username"
            placeholder="Username"
            value={appUsername}
            onChange={(e) => setAppUsername(e.target.value)}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            value={appPassword}
            onChange={(e) => setAppPassword(e.target.value)}
          />
          {invalidCredentials && (
            <p style={{ color: "red", margin: 0 }}>Invalid credentials</p>
          )}
          <button className={styles.button}>Login</button>
        </form>
      </Modal>
      <div className={styles.informationContainer}>
        <FormControl>
          <label>User</label>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={selectedTestUser}
            className={styles.select}
            placeholder="Select a user"
            onChange={handleDropdownChange}
          >
            {testUsers?.map((user, idx) => (
              <MenuItem key={idx} value={user}>
                <div className={styles.menuItem}>
                  <p style={{ fontWeight: "bold" }}>{user.firstname}</p>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={styles.container}>
          <Demography
            demography={selectedTestUserInformation?.demography}
            isLoading={isLoading}
          />
          <LastClinicalIntake
            last_clinical_intake={
              selectedTestUserInformation?.last_clinical_intake
            }
            isLoading={isLoading}
          />
          <AppointmentHistory
            appointments={selectedTestUserInformation?.appointment_history}
            isLoading={isLoading}
          />
          <MessageHistory
            messages={selectedTestUserInformation?.message_history}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
