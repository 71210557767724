import { Input, Switch, Tab, Tabs } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { json, useSearchParams } from "react-router-dom";
import {
  getUser,
  getUsers,
  getUserSessionId,
  loadBotData,
} from "../../API/API";
import { makeNotif } from "../Toastify";
import "./users.css";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selectedUserIndex, setSelectedUserIndex] = useState("");
  const [conversation, setConversation] = useState("");
  const [information, setInformation] = useState({});
  const [appUsername, setAppUsername] = useState(Cookies.get("username"));
  const [appPassword, setAppPassword] = useState(Cookies.get("password"));
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [checked, setChecked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [usersFromAPI, setUsersFromAPI] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchSessionId, setSearchSessionId] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchUsers = async () => {
    try {
      const res = await getUsers();
      setUsers(res);
    } catch (error) {
      console.error("Error getting users: ", error);
    }
  };

  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1000,
    },
  };

  const fetchData = async (source) => {
    if ((appUsername && appPassword) || source === "form") {
      try {
        const data = await loadBotData(appUsername, appPassword);
        if (data.message !== "Incorrect credentials") {
          Cookies.set("username", appUsername);
          Cookies.set("password", appPassword);
          setInvalidCredentials(false);
          setModalIsOpen(false);
          fetchUsers();
          const userIdentification = searchParams.get("userIdentification");
          const userPassword = searchParams.get("userPassword");
          if (userIdentification && userPassword) {
            fetchFromQuery(userIdentification, userPassword);
          }
        } else {
          setInvalidCredentials(true);
          setModalIsOpen(true);
          Cookies.remove("username");
          Cookies.remove("password");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          setModalIsOpen(true);
        }
      }
    }
  };

  const handleSearchButtonClick = async (source) => {
    if (activeTab === 0) {
      if (username === "" || password === "") {
        if (source === "debug switch") return;
        makeNotif("error", "Please enter User Identification and Password");
        return;
      }
      try {
        const res = await getUser(username, password, checked);
        setConversation(
          res.conversation
            ?.replace(/Copilot:/g, '<span style="color: green;">Coach:</span>')
            ?.replace(/Member:/g, '<span style="color: blue;">Member:</span>')
            ?.replace(
              /Comment:/g,
              '<span style="color: orange;">Comment:</span>'
            )
        );
        setInformation(res.information);
        setSelectedUserIndex("");
      } catch (error) {
        makeNotif("error", `Error fetching user data`);
        console.log(error.message);
      }
    }
    if (activeTab === 1) {
      if (searchSessionId === "") {
        if (source === "debug switch") return;
        makeNotif("error", "Please enter Session ID");
        return;
      }
      try {
        const res = await getUserSessionId(searchSessionId, checked);
        setConversation(
          res.data?.conversation
            ?.replace(/Copilot:/g, '<span style="color: green;">Coach:</span>')
            ?.replace(/Member:/g, '<span style="color: blue;">Member:</span>')
            ?.replace(
              /Comment:/g,
              '<span style="color: orange;">Comment:</span>'
            )
        );
        setInformation(res.data?.information);
        setSelectedUserIndex("");
      } catch (error) {
        makeNotif("error", `Error fetching user data`);
      }
    }
  };

  const handleDropdownChange = async (event) => {
    const selectedIndex = event.target.value;
    setSelectedUserIndex(selectedIndex);
    if (selectedIndex !== "") {
      const selectedUser = users[selectedIndex];
      try {
        const res = await getUser(
          selectedUser.username,
          selectedUser.password,
          checked
        );
        setConversation(
          res.conversation
            ?.replace(/Copilot:/g, '<span style="color: green;">Coach:</span>')
            ?.replace(/Member:/g, '<span style="color: blue;">Member:</span>')
            ?.replace(
              /Comment:/g,
              '<span style="color: orange;">Comment:</span>'
            )
        );
        setInformation(res.information);
        setUsername("");
        setPassword("");
        setSearchSessionId("");
      } catch (error) {
        makeNotif("error", `Error fetching user data`);
      }
    }
  };

  useEffect(() => {
    if (activeTab === 0 || activeTab === 1) {
      handleSearchButtonClick("debug switch");
    } else {
      handleDropdownChange({ target: { value: selectedUserIndex } });
    }
  }, [checked]);

  const handleLogin = async (e) => {
    e.preventDefault();
    await fetchData("form");
  };

  const fetchFromQuery = async (username, password) => {
    try {
      setUsername(username);
      setPassword(password);
      const res = await getUser(username, password, false);
      setConversation(
        res.conversation
          ?.replace(/Copilot:/g, '<span style="color: green;">Coach:</span>')
          ?.replace(/Member:/g, '<span style="color: blue;">Member:</span>')
          ?.replace(/Comment:/g, '<span style="color: orange;">Comment:</span>')
      );
      setInformation(res.information);
    } catch (error) {
      makeNotif("error", `Error fetching user data`);
    }
  };

  const handleClear = (fields) => {
    if (fields === "sessionId") {
      setSearchSessionId("");
      return;
    }
    if (fields === "usernamePassword") {
      setUsername("");
      setPassword("");
    }
  };

  useEffect(() => {
    fetchData("useEffect");
    fetchUsers();
  }, []);

  // console.log(JSON.parse(information["Plan 1"]["Success Plan"]));

  return (
    <div className="users-container">
      <Modal isOpen={modalIsOpen} style={modalStyle}>
        <form className="login-form" onSubmit={handleLogin}>
          <Input
            type="text"
            name="username"
            placeholder="Username"
            value={appUsername}
            onChange={(e) => setAppUsername(e.target.value)}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            value={appPassword}
            onChange={(e) => setAppPassword(e.target.value)}
          />
          {invalidCredentials && (
            <p style={{ color: "red", margin: 0 }}>Invalid credentials</p>
          )}
          <button className="button">Login</button>
        </form>
      </Modal>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Search by User Information" />
        <Tab label="Search by Session ID" />
        <Tab label="Manual Selection" />
      </Tabs>

      {activeTab === 0 && (
        <div className="search-container">
          <div className="input-container">
            <Input
              placeholder="User Identification"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* {(username !== "" || password !== "") && (
              <button
                className="clear-button button"
                onClick={() => handleClear("usernamePassword")}
              >
                Clear
              </button>
            )} */}
          </div>
          <div className="switch-container">
            <p>Debugging</p>
            <div className="debug-switch">
              <p>OFF</p>
              <Switch
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
              <p>ON</p>
            </div>
          </div>
          <button className="button" onClick={handleSearchButtonClick}>
            Search
          </button>
        </div>
      )}

      {activeTab === 1 && (
        <div className="session-id-container">
          <Input
            placeholder="Session ID"
            value={searchSessionId}
            onChange={(e) => setSearchSessionId(e.target.value)}
          />
          {/* {searchSessionId !== "" && (
            <button
              className="clear-button button"
              onClick={() => handleClear("sessionId")}
            >
              Clear
            </button>
          )} */}
          <div className="switch-container">
            <p>Debugging</p>
            <div className="debug-switch">
              <p>OFF</p>
              <Switch
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
              <p>ON</p>
            </div>
          </div>
          <button className="button" onClick={handleSearchButtonClick}>
            Search
          </button>
        </div>
      )}

      {activeTab === 2 && (
        <div className="select-container">
          <div className="select-options">
            <select
              id="dropdown"
              className="dropdown"
              value={selectedUserIndex}
              onChange={handleDropdownChange}
            >
              <option value="" disabled>
                Select a user
              </option>
              {users?.length > 0 &&
                users.map(
                  (user, idx) =>
                    user.is_from_api === usersFromAPI && (
                      <option key={idx} value={idx}>
                        {user.username} {` - ${user.password}`}{" "}
                        {user.start_time && ` - ${user.start_time}`}
                      </option>
                    )
                )}
            </select>
            <div className="select-switch">
              <p>Show users from API:</p>
              <Switch
                checked={usersFromAPI}
                onChange={() => setUsersFromAPI((prev) => !prev)}
              />
            </div>
          </div>
          <div className="switch-container">
            <p>Debugging</p>
            <div className="debug-switch">
              <p>OFF</p>
              <Switch
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
              <p>ON</p>
            </div>
          </div>
        </div>
      )}

      <div className="content">
        <div className="text">
          <h2>Conversation</h2>
          <div
            id="conversation"
            dangerouslySetInnerHTML={{ __html: conversation }}
          ></div>
        </div>
        <div className="text">
          <h2>Memory</h2>
          <div id="information">
            <ul>
              {Object.entries(information).map(([key, value]) => (
                <li key={key}>
                  <strong>{key}:</strong>
                  {typeof value === "object" && !Array.isArray(value)
                    ? Object.entries(value).map(([subKey, subValue]) => (
                        <div key={subKey}>
                          <strong>{subKey}:</strong> {JSON.stringify(subValue)}
                        </div>
                      ))
                    : Array.isArray(value)
                    ? value.map((item, index) => (
                        <div key={index}>
                          {typeof item === "object"
                            ? JSON.stringify(item)
                            : item}
                        </div>
                      ))
                    : value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
