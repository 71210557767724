// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contents-container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.contents {
  width: 100%;
  padding: 16px 50px 16px 16px;
  border: 1px solid black;
  border-radius: 10px;
}

.content-item {
  text-align: justify;
  line-height: 26px;
  margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/contentIds/contentIds.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,4BAA4B;EAC5B,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".contents-container {\n  padding: 50px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n}\n\n.contents {\n  width: 100%;\n  padding: 16px 50px 16px 16px;\n  border: 1px solid black;\n  border-radius: 10px;\n}\n\n.content-item {\n  text-align: justify;\n  line-height: 26px;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
