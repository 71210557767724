import React, { createContext, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Chatbox from "./Components/Chatbox/Chatbox";
import Users from "./Components/users/users";
import ContentIds from "./Components/contentIds/contentIds";
import Information from "./Components/information/information";
import "./App.css";
import Whisper from "./Components/Whisper/Whisper";

export const ThemeContext = createContext(null);
function App() {
  const [theme, setTheme] = useState("light");
  const [type, setType] = useState();
  const [version, setVersion] = useState();
  const [averageResponseTime, setAverageResponseTime] = useState(null);
  const toggleTheme = () => {
    setTheme((curTheme) => (curTheme === "dark" ? "light" : "dark"));
  };
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <Routes>
        <Route path="/" element={<Navigate to="/chat" />} />
        <Route
          path="/chat"
          element={
            <div className="App" id={theme}>
              <div className="app-container">
                {/* {states && <States states={states} />} */}
                <div className="chatbox-container">
                  <h2 className="bot-version">
                    {type}-v{version}
                  </h2>
                  <Chatbox setType={setType} setVersion={setVersion} />
                </div>
              </div>
            </div>
          }
        />
        <Route
          path="/chat/new"
          element={
            <div className="App" id={theme}>
              <div className="app-container">
                {/* {states && <States states={states} />} */}
                <div className="chatbox-container">
                  <h2 className="bot-version">
                    {type}-v{version}
                  </h2>
                  <Chatbox setType={setType} setVersion={setVersion} />
                </div>
              </div>
            </div>
          }
        />
        <Route path="/users" element={<Users />} />
        <Route path="/content" element={<ContentIds />} />
        <Route path="/information" element={<Information />} />
        <Route path="/whisper" element={<Whisper />} />
      </Routes>
      <ToastContainer />
    </ThemeContext.Provider>
  );
}

export default App;
